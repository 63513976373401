import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

// Stop Font Awesome from adding styles to <head> when
// a <FontAwesomeIcon /> is rendered the first time.
// We have already added these styles via CSS import.
import '@fortawesome/react-fontawesome'
if (typeof window !== 'undefined' && window.FontAwesomeConfig) {
  window.FontAwesomeConfig.autoAddCss = false
}

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <div className="grid-container kokoPaske">{children}</div>}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
